import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://fd0fa04b03386315dc222b1f0eb56ecc@o4506249448194048.ingest.sentry.io/4506249449897984",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(
        {
            maskAllText: false,
            blockAllMedia: false
        }),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "system",
            isNameRequired: true,
            isEmailRequired: true,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

